import * as React from "react"

import Navbar from  '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import styled from 'styled-components';
import  ErroPageImage from '../../static/img/HTML-404-Error-Page.gif';

const NotFoundPage = () => (
<div>
<Navbar></Navbar>
<Container>
  
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    
    </Container>
    <Footer></Footer>

</div>
  
  

)


const Container = styled.div`

background: url(${ErroPageImage}); /* The least supported option. */
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
  background-size: cover;
color:#fff;
height: 800px;
`;

export default NotFoundPage
